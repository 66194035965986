<template>
  <main class="login-page">
    <form class="login-form">
      <h1 class="login-form-element">Вхід до системи</h1>
      <input
        v-model="name"
        class="login-form-element login-form-input"
        placeholder="Ім'я "
        type="text"
      />
      <input
        v-model="password"
        class="login-form-element login-form-input"
        placeholder="Пароль"
        type="password"
      />
      <button @click.prevent="login" class="login-form-element login-form-button">Вхід</button>
    </form>

    <div id="popup" :class="['overlay', {'popup-active': popupSettings.popupActive}]">
      <div class="popup">
        <h2>{{popupSettings.popupHeading}}</h2>
        <a
          @click.prevent="() => {popupSettings.popupActive = !popupSettings.popupActive}"
          class="close"
          href="#"
        >&times;</a>
        <div class="content">{{popupSettings.popupMessage}}</div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Login",
  data: () => ({
    name: "",
    password: "",
    popupSettings: {
      popupActive: false,
      popupHeading: "",
      popupMessage: ""
    }
  }),
  methods: {
    async login() {
      const requestPayload = { name: this.name, password: this.password };
      try {
        console.log(1);
        const response = await fetch("/api/v1/admins/auth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8"
          },
          body: JSON.stringify(requestPayload)
        });
        console.log(2);
        const data = await response.json();

        if (data.status === "success") {
          setTimeout(() => location.assign("/admin/dashboard"), 2500);
          this.showPopup("success");
        }
      } catch (error) {
        this.showPopup("error");
      } finally {
        this.name = "";
        this.password = "";
      }
    },
    showPopup(status) {
      status === "success"
        ? (this.popupSettings.popupHeading = "Успіх")
        : (this.popupSettings.popupHeading = "Помилка входу");
      status === "success"
        ? (this.popupSettings.popupMessage = "Вхід у систему пройшов успішно.")
        : (this.popupSettings.popupMessage =
            "Сталася помилка. Перевірте правильність данних та спробуйте ще раз.");

      this.popupSettings.popupActive = true;
    }
  }
};
</script>
<style scoped>
main.login-page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: linear-gradient(#696f79, #293863);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  /* width: (100% - 30px); */
  min-width: 400px;
  background: #e0e6eb;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 15%),
    0 8px 24px 0 rgba(55, 74, 126, 20%);
}

.login-form-element {
  width: calc(100% - 60px);

  height: 30px;
  margin: 8px auto;
}

.login-form-element:focus {
  outline: none;
}

.login-form-input,
.login-form-button {
  font-size: 16px;
  padding: 0 5px;
  border-color: rgba(0, 0, 0, 25%);
}

.login-form-button {
  margin: 30px auto;
  background: #293863;
  color: white;
  transition: 0.3s;
}

h1.login-form-element {
  width: 100%;
  display: inline-block;
  text-align: center;
  background: white;
  height: 62px;
  font-size: 26px;
  padding: 18px;
  margin: 0 0 30px 0;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 20%);
}

.login-form-button:hover {
  /* background: rgb(168, 170, 172);
  color: black; */
  background: #51b2df;
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: (100% - 30px);
    min-width: unset;
    background: #e0e6eb;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 15%),
      0 8px 24px 0 rgba(55, 74, 126, 20%);
  }

  .login-form-element {
    width: calc(100vw - 70px);

    height: 30px;
    margin: 8px 20px;
  }

  .login-form-element:focus {
    outline: none;
  }

  .login-form-input,
  .login-form-button {
    font-size: 16px;
    padding: 0 5px;
    border-color: rgba(0, 0, 0, 25%);
  }

  .login-form-button {
    margin: 30px auto;
    background: #293863;
    color: white;
    transition: 0.3s;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
}
.overlay.popup-active {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}
</style>